import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import MetaData from "../components/meta"

// Import styles
import "../styles/contact.scss"

// Import images
import header__illustration_left from "../images/contact/illustration-left.svg"
import header__illustration_right from "../images/contact/illustration-right.svg"
import cta__twitter from "../images/contact/twitter-icon.svg"
import cta__community from "../images/contact/community-icon.svg"
import cta__linkedin from "../images/contact/linked-in-icon.svg"

const ContactUsPage = ({ data, location }) => {
  useEffect(() => {
    let script = document.createElement("script")
    script.src = "//js.hsforms.net/forms/v2.js"
    script.onload = function () {
      hbspt.forms.create({
        portalId: "5814022",
        formId: "ab73c107-d937-4c82-9f71-1aa051260dc9",
        target: "#hubspot-contact-us-form",
      })
    }
    document.getElementsByTagName("head")[0].appendChild(script)
  }, [])

  return (
    <Layout>
      <MetaData
        location={location}
        data={{
          ghostPage: data.current_page,
        }}
      />

      <div className="contact">
        {/* Header */}
        <section className="contact__header">
          <div className="wrapper-1200">
            <h1>Get in touch</h1>
            <p>
              We are here to help! Fill out the form and let us know how we can
              help
            </p>
          </div>
          <div className="images-layer">
            <img src={header__illustration_left} />
            <img src={header__illustration_right} />
          </div>
        </section>

        {/* Form */}
        <section className="contact__body">
          <div className="wrapper-1200">
            <hr className="hr-tiny" />
            <h2>We've been expecting you</h2>
            <div
              id="hubspot-contact-us-form"
              className="hubspot-form-wrapper"
            ></div>
          </div>
        </section>

        {/* CTA */}
        <section className="contact__footer">
          <div className="wrapper-1200">
            <hr className="hr-tiny" />
            <h2>Connect with us</h2>
            <div className="links">
              <a href="https://twitter.com/traefik" className="link">
                <img src={cta__twitter} />
                <div className="title">@CONTAINOUS</div>
                <div className="link--with-arrow">Follow us on Twitter</div>
              </a>
              <a href="https://community.traefik.io/" className="link">
                <img src={cta__community} />
                <div className="title">community.traefik.io</div>
                <div className="link--with-arrow">Community Forum</div>
              </a>
              <a
                href="https://www.linkedin.com/company/traefik/"
                className="link"
              >
                <img src={cta__linkedin} />
                <div className="title">traefik</div>
                <div className="link--with-arrow">Follow us on LinkedIn</div>
              </a>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default ContactUsPage

export const query = graphql`
  query {
    current_page: ghostPage(slug: { eq: "contact-us" }) {
      ...PageMetaFields
    }
  }
`
